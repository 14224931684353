<template>
  <div style="background:#EDEDED;">
    <alipay-js></alipay-js>
    <div style="text-align:center;" class="gz-pt-3">
      <img :src="imgSrc" style="width:15%;border-radius: 50%;    border: 2px solid #FFF;">
      <h2>{{description}}</h2>
    </div>
    <h2 style="text-align:center;">请输入付款金额</h2>
    <div class="gz-px-3">
      <div class="gz-layout gz-layout-row" style="    background: rgb(255, 255, 255);border-radius: 0.2em;font-size: 28px;padding: 8px;font-weight: bold;">
        <div style="padding: 10px 15px;">￥</div>
        <div class="gz-layout-item">
          <calculation @confirmEvent="_confirmEvent" v-model="amount"></calculation>
        </div>
      </div>
    </div>
    <div class="gz-px-3 gz-mt-3">
      <button class="btnPay" @click="onSubmit">支付</button>
    </div>
    <div v-if="wxsdkStatus==true" class="gz-p-2 gz-pt-3">

    </div>
    <div v-else>
      页面初始化...
    </div>
  </div>
</template>
<style lang="less" scoped>
.amount {
  width: 100%;
  font-size: inherit;
  border: none;

  outline: none;
  &:focus,
  &:focus-visible {
    border: none;
  }
}
.btnPay {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 2.3;
  border-radius: 0.25rem;
  user-select: none;
  width: 100%;
}
</style>
<script>
/* eslint-disable no-undef */
import serverConfig from '../config/index'
import calculation from './keyboard/calculation.vue'
import { ajax } from 'yesweb-ajax';
export default {
  components: {
    calculation,
    'alipay-js': {
      render (createElement) {
        return createElement(
          'script',
          {
            attrs: {
              type: 'text/javascript',
              src: 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.inc.min.js',
            },
          },
        );
      },
    },
  },
  data () {
    return {
      amount: '',
      userID: '',
      wxsdkStatus: true,

      description: '',
      imgURL: '',
    }
  },
  computed: {
    imgSrc () {
      return serverConfig.serverHost + "/fileServer" + this.imgURL;
    },
  },
  created () {
    window.document.title = "向商户付款";
    var me = this;
    let auth_code = this.$route.query.auth_code

    ajax.get(this, '/onlinepay/H5Pay/GetAliUserID', { auth_code }).then(res => {
      this.userID = res.data.userID;
    })

    ajax.get(this, '/onlinepay/H5Pay/GetCurrentAttribute').then(res => {
      this.imgURL = res.data.img;
      this.description = res.data.description
    })
  },
  methods: {

    onSubmit () {
      ajax.get(this, '/onlinepay/H5Pay/AliPay', {
        qrCode:this.$store.state.qrCode,
        userID: this.userID,
        amount: this.amount
      }).then(res => {
        if ((res.data.tradeNo || '') == '') {
          alert(res.data.message);
          return
        }

        ap.tradePay({
          tradeNO: res.data.tradeNo
        }, function (res) {
          if (res.resultCode == 9000) {
            ap.popWindow();
          } else {
            ap.alert(res.resultCode);
            ap.alert(JSON.stringify(res));
          }
        });
      })
    },
    _confirmEvent (res) {
      console.log(res)
    }
  }
}
</script>